<template lang="pug">
  tr.matchings-item.car-class
    td.item-name
      AppToggle(
        :value="carClass.enabled.$model"
        @change="toggleEnabled"
      )
    td.item-name-dropdown
      span
        | {{ carClass.name.$model }}
    td
      AppNumberInput.alert-threshold(
        :value="carClass.alert_threshold.$model"
        :disabled="!carClass.enabled.$model"
        :invalid="carClass.alert_threshold.$error"
        @input="handleInput($event)"
      )
</template>

<script>
  export default {
    props: {
      carClass: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        alertThreshold: this.carClass.alert_threshold
      }
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    methods: {
      toggleEnabled() {
        this.updateCarClassPartial({ enabled: !this.carClass.enabled.$model })
      },

      handleInput(value) {
        this.updateCarClassPartial({ alert_threshold: value })
      },

      updateCarClassPartial(changes) {
        const item = { ...this.carClass.$model, ...changes }
        this.$emit("change-car-class", item)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .matchings-item
    &.car-class
      transition: all 1s linear
      background-color: $default-gray-light

    td
      +matchings-item-td

      ::v-deep
        input
          width: 100px

          &.invalid
            +default-invalid-input

        &.app-toggle
          margin-left: 10px
</style>
